<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-13 09:33:34
 * @LastEditTime: 2023-07-04 11:20
 * @Descripttion: 【系统】角色管理
-->
<style lang="scss" scoped>
.role {
    @include innerPage(56px 34px 48px 15px);
    @include pageTitle(0);
    @include defalutTable(calc(100% - 38px), 20px);
    @include pageFoot;
}

.dialog-container {
    box-sizing: border-box;
    padding: 34px 20px 48px 18px;

    .el-form-item:last-child {
        margin-bottom: 0;
    }

    .authority-tree {
        max-height: 480px;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding-left: 30px;
    }
}
</style>

<template>
    <section class="role">
        <div class="page-title">
            <h3>角色管理</h3>
            <el-button class="light" type="custom_primary" size="medium" @click="roleDialog = true">
                新增+
            </el-button>
        </div>
        <div class="table-box">
            <div class="table-inner" ref="tableInner">
                <el-table :data="tableData" height="100%">
                    <el-table-column align="center" prop="arole_name" label="角色名称" min-width="39%" />
                    <el-table-column align="center" prop="arole_status" label="状态" min-width="30%">
                        <template slot-scope="scope">
                            <p class="table-status"
                                :class="{ success: scope.row.arole_status == 1, failed: scope.row.arole_status == 0 }">
                                {{ scope.row.arole_status == 1 && '启用' || '禁用' }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" min-width="31%">
                        <template slot-scope="scope">
                            <el-button type="custom_warning" size="mini" plain @click="updateMenu(scope.row)"
                                :disabled="scope.row.arole_issuper == 1">
                                分配菜单
                            </el-button>
                            <el-button type="custom_primary" size="mini" plain @click="updateRole(scope.row)"
                                :disabled="scope.row.arole_issuper == 1">
                                编辑
                            </el-button>
                            <el-button type="custom_danger" size="mini" plain @click="removeRole(scope.row)"
                                :disabled="scope.row.arole_issuper == 1">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-foot custom-foot">
                <customPagination :current='searchForm.pageindex' :total="total" @pageChange="flippingPage" />
            </div>
        </div>
        <el-dialog :title="roleForm.arole_id && '编辑角色' || '新增角色'" width="558px" :visible.sync="roleDialog"
            :show-close="false" @close="hideDialog('role')">
            <div class="dialog-container">
                <el-form label-width="70px" ref="roleForm" :model="roleForm" :rules="roleRule">
                    <el-form-item label="角色名称" prop="arole_name">
                        <el-input v-model.trim="roleForm.arole_name" maxlength="100" placeholder="请输入内容" />
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-switch v-model="roleForm.arole_status" :active-value="1" :inactive-value="0"
                            :active-text="roleForm.arole_status == 1 && '启用' || '禁用'" />
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button type="custom_info" size="small" @click="hideDialog('role')">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" @click="handleConfirm" :disabled="btnload"
                    v-loading="btnload">
                    确 定
                </el-button>
            </div>
        </el-dialog>
        <el-dialog title="分配菜单" width="498px" :visible.sync="authMenuDialog" :show-close="false"
            @close="hideDialog('authMenu')">
            <div class="dialog-container">
                <div class="authority-tree">
                    <el-tree ref="Menutree" :data="authMenuData" :props="menuDefaultProps" show-checkbox
                        node-key="amenu_id" />
                </div>
            </div>
            <div slot="footer">
                <el-button type="custom_info" size="small" @click="hideDialog('authMenu')">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" @click="menuConfirm" :disabled="btnload" v-loading="btnload">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import { roleList, roleAdd, roleEdit, roleRemove, saverolemenu, getrolemenuid, gettreemenu } from "@api/system";
import { mapActions } from "vuex"
import customPagination from "@comp/customPagination";
export default {
    name: 'system_role',
    components: {
        customPagination
    },
    data() {
        return {
            tableData: [],
            searchForm: {
                pageindex: 1,
                pagesize: 10,
            },
            total: 0,
            type: "add",
            roleDialog: false,
            roleForm: { arole_status: 1 },
            roleRule: {
                arole_name: [{ required: true, message: "请输入角色名称", trigger: "blur" },]
            },
            authorityDialog: false,
            roleid: null,
            authorityData: [],
            defaultProps: {
                children: 'children',
                label: 'anode_nickname',
            },
            authMenuDialog: false,
            authMenuData: [],
            menuDefaultProps: {
                children: 'children',
                label: 'amenu_name',
            },
            btnload: false,
        };
    },
    created() {
        this.init();
        this.getMenuThree();
    },
    methods: {
        ...mapActions('common', 'getMenuRoot'),
        loadNode(node, resolve) {
            if (node.level === 0) {
                return resolve([{ name: 'region' }]);
            }
            if (node.level > 1) return resolve([]);
            setTimeout(() => {
                const data = [{
                    name: 'leaf',
                    leaf: true
                }, {
                    name: 'zone'
                }];

                resolve(data);
            }, 500);
        },
        /**初始化 */
        async init() {
            const res = await roleList(this.searchForm);
            if (res.status == 200) {
                this.tableData = res.data.data;
                this.total = res.data.allcount;
            }
        },
        /** 切换页码 */
        flippingPage(val) {
            this.searchForm.pageindex = val;
            this.init();
        },
        /** 确认添加 */
        handleConfirm() {
            this.$refs.roleForm.validate(async (valid) => {
                if (valid) {
                    let res = this.roleForm.arole_id && await roleEdit(this.roleForm) || await roleAdd(this.roleForm);
                    if (res.status == 200) {
                        this.$message.success(this.roleForm.arole_id && "编辑成功" || "添加成功");
                        this.hideDialog('role');
                        this.init();
                    }
                }
            });
        },
        /** 编辑角色 */
        updateRole(row) {
            this.roleForm = {
                arole_status: row.arole_status,
                arole_name: row.arole_name,
                arole_id: row.arole_id
            };
            this.roleDialog = true;
        },
        /** 删除角色 */
        removeRole(row) {
            this.$msgbox({
                title: '删除',
                message: '确认删除当前角色？',
                type: 'warning',
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: 'el-button--custom_info',
                confirmButtonClass: 'el-button--custom_primary',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                const res = await roleRemove({ id: row.arole_id });
                if (res.status == 200) {
                    this.$message({
                        type: 'success',
                        duration: 1500,
                        message: '角色已删除！'
                    });
                    this.total = this.total - 1 < 0 && 0 || this.total - 1;
                    if (this.tableData.length == 1 && this.searchForm.pageindex != 1) {
                        this.searchForm.pageindex = this.searchForm.pageindex - 1;
                    }
                    this.init();
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    duration: 1500,
                    message: '操作已取消'
                })
            });
        },
        /** 菜单树状列表 */
        async getMenuThree() {
            const res = await gettreemenu();
            if (res.status == 200) {
                this.authMenuData = res.data;
            }
        },
        /** 编辑菜单权限 */
        async updateMenu(row) {
            const res = await getrolemenuid({ issuper: row.arole_issuper, roleid: row.arole_id })
            if (res.status == 200) {
                this.authMenuDialog = true;
                this.$nextTick(() => {
                    let arr = []
                    res.data.map(item => {
                        let node = this.$refs.Menutree.getNode(item);
                        if (node.isLeaf) {
                            this.$refs.Menutree.setChecked(node, true);
                        }
                        if (!node.expanded && !node.isLeaf) {
                            node.expand()
                        }
                    })
                    this.roleid = row.arole_id;
                });
            }

        },
        /** 数组遍历为一维数组 */
        flattenDeep(data, newval = []) {
            data.forEach(item => {
                newval.push(item.amenu_id)
                if (!this.$isEmpty(item.children)) this.flattenDeep(item.children, newval);
            })
            return newval;
        },
        /** 菜单权限确认 */
        async menuConfirm() {
            let params = { roleid: this.roleid, menuarr: [] }
            let nodearr = this.$refs.Menutree.getCheckedNodes(false, true);
            if (this.$isEmpty(nodearr)) return this.$message.error('请勾选当前角色可访问的菜单权限');
            nodearr.map(item => params.menuarr.push({ amenu_id: item.amenu_id }));
            const res = await saverolemenu(params);
            if (res.status == 200) {
                this.$message.success("配置成功");
                this.authMenuDialog = false;
                this.getMenuRoot();
            }
        },
        /** 关闭弹窗 */
        hideDialog(el) {
            this[`${el}Dialog`] = false;
            if (el == 'role') {
                this.$refs.roleForm.resetFields();
                this.roleForm = { arole_status: 1 };
            } else {
                this.$refs.Menutree.setCheckedKeys([]);
                let elMenu = this.flattenDeep(this.authMenuData);
                elMenu.map(item => {
                    let node = this.$refs.Menutree.getNode(item);
                    if (!node.isLeaf) {
                        node.collapse()
                    }
                })
                this.roleid = null;
            }

        },
    },
}
</script>